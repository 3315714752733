
import { type LabelType, type PrintLabelResponse, type RASN } from 'types'

import { mainSplitApi } from './redux/mainSplitApi'

export const rasnApi = mainSplitApi.injectEndpoints({
  endpoints: (builder) => ({
    createRasn: builder.mutation<RASN, string>({
      query: (rasnId) => {
        return {
          url: `/rasns/${rasnId}`,
          method: 'POST'
        }
      }
    }),

    updateRasn: builder.mutation<RASN, RASN>({
      query: (rasn) => {
        return {
          url: '/rasns',
          method: 'PUT',
          body: rasn
        }
      }
    }),

    getRasnProcessedQuantity: builder.query<RASN, string>({
      query: (rasn) => {
        return {
          url: `/rasns/${rasn}`,
          method: 'GET'
        }
      }
    }),

    printRasnLpnLabel: builder.mutation<PrintLabelResponse, { rasnNumber: string, labelType: LabelType }>({
      query: ({ rasnNumber, labelType }) => {
        return {
          url: `/rasns/${rasnNumber}/label?type=${labelType}`,
          method: 'POST'
        }
      }
    })
  }),

  overrideExisting: false
})

export const { useCreateRasnMutation, useUpdateRasnMutation, useLazyGetRasnProcessedQuantityQuery, usePrintRasnLpnLabelMutation } = rasnApi
